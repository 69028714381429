<template>
  <div class="relative w-full max-w-xs">
    <button class="absolute left-3 top-1/2 transform -translate-y-1/2">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.7556 18.5783L14.0682 12.8908C15.1699 11.53 15.8332 9.80088 15.8332 7.91758C15.8332 3.55262 12.2815 0.000976562 7.91656 0.000976562C3.55161 0.000976562 0 3.55258 0 7.91754C0 12.2825 3.55165 15.8341 7.9166 15.8341C9.7999 15.8341 11.5291 15.1708 12.8899 14.0692L18.5773 19.7566C18.7398 19.9191 18.9531 20.0008 19.1665 20.0008C19.3798 20.0008 19.5932 19.9191 19.7557 19.7566C20.0815 19.4308 20.0815 18.9041 19.7556 18.5783ZM7.9166 14.1675C4.46996 14.1675 1.66666 11.3642 1.66666 7.91754C1.66666 4.47089 4.46996 1.6676 7.9166 1.6676C11.3632 1.6676 14.1665 4.47089 14.1665 7.91754C14.1665 11.3642 11.3632 14.1675 7.9166 14.1675Z"
          fill="#212022"
        />
      </svg>
    </button>
    <input
      class="
        text-base text-black-900
        rounded
        border border-grey-500
        pl-12
        pr-8
        py-2
        w-full
        focus:border-black-900
        outline-none
      "
      :class="{ 'py-2.5': big }"
      type="text"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @keydown.enter="$emit('handleEnter')"
      :placeholder="placeholder"
    />
    <button
      v-show="value.length > 0 && $route.name !== 'search'"
      @click="$emit('handleClear')"
      class="absolute right-3 top-1/2 transform -translate-y-1/2"
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.43596 0.435471C0.826485 0.0449471 1.45965 0.0449471 1.85017 0.435471L9.00021 7.58551L16.1502 0.435471C16.5408 0.0449471 17.1739 0.0449471 17.5645 0.435471C17.955 0.825996 17.955 1.45916 17.5645 1.84968L10.4144 8.99972L17.5645 16.1498C17.955 16.5403 17.955 17.1734 17.5645 17.564C17.1739 17.9545 16.5408 17.9545 16.1502 17.564L9.00021 10.4139L1.85017 17.564C1.45965 17.9545 0.826484 17.9545 0.43596 17.564C0.0454353 17.1734 0.0454353 16.5403 0.43596 16.1498L7.586 8.99972L0.43596 1.84968C0.0454361 1.45916 0.0454361 0.825996 0.43596 0.435471Z"
          fill="#212022"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: () => "Search...",
    },
    big: {
      type: Boolean,
      default: () => false,
    },
    value: {
      required: true,
    },
  },
};
</script>
